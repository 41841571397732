<template>
  <!-- begin:: Aside -->
  <div
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto box-shadow"
    id="dp_aside"
    ref="dp_aside"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- begin:: Aside -->
    <DPBrand></DPBrand>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div
      class="aside-menu-wrapper flex-column-fluid"
      id="dp_aside_menu_wrapper"
    >
      <div
        ref="dp_aside_menu"
        id="dp_aside_menu"
        class="aside-menu"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        :class="asideMenuClass"
        style="height: 100%;"
      >
        <!-- example static menu here -->
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 90vh; position: relative;height: 100%;"
        >
          <DPMenu></DPMenu>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from 'vuex';
import DPLayoutAside from '@/assets/js/layout/base/aside.js';
import DPLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js';

export default {
  name: 'DPAside',
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
    };
  },
  components: {
    DPBrand: () => import('@/view/layout/brand/Brand.vue'),
    DPMenu: () => import('@/view/layout/aside/Menu.vue'),
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      DPLayoutAside.init(this.$refs['dp_aside']);

      // Init Aside Menu
      DPLayoutAsideMenu.init(this.$refs['dp_aside_menu']);
    });
  },
  methods: {
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      // check if the left aside menu is fixed
      if (document.body.classList.contains('aside-fixed')) {
        if (this.outsideTm) {
          clearTimeout(this.outsideTm);
          this.outsideTm = null;
        }

        // if the left aside menu is minimized
        if (document.body.classList.contains('aside-minimize')) {
          document.body.classList.add('aside-minimize-hover');

          // show the left aside menu
          document.body.classList.remove('aside-minimize');
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (document.body.classList.contains('aside-fixed')) {
        if (this.insideTm) {
          clearTimeout(this.insideTm);
          this.insideTm = null;
        }

        // if the left aside menu is expand
        if (document.body.classList.contains('aside-minimize-hover')) {
          // hide back the left aside menu
          document.body.classList.remove('aside-minimize-hover');
          document.body.classList.add('aside-minimize');
        }
      }
    },
  },
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses('aside_menu');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  border-right: 1px solid #dcdcdc;

}


  
</style>
